<template>
  <b-modal
    id="edit-nutrient-sidebar"
    :visible="isEditNutrientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    centered
    size="lg"
    :ok-title="$t('ok')"
    ok-only
    :title="$t('editNutrient')"
    @ok="onSubmit(blankNutrientData)"
    @hidden="formValidation(resetblanknutrient).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblanknutrient
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        class="p-2 d-flex"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <div class="w-50">
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- Kod -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('code')" label-for="kod">
              <b-form-input
                id="kod"
                v-model="blankNutrientData.kod"
                autofocus
                :state="
                  formValidation(resetblanknutrient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ad -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="name"
          >
            <b-form-group :label="$t('name')" label-for="name">
              <b-form-input
                id="name"
                v-model="blankNutrientData.nutrientname"
                autofocus
                :state="
                  formValidation(resetblanknutrient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group :label="$t('type')" label-for="type">
            <v-select
              v-model="blankNutrientData.tipid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tipid"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => changeTip(val)"
            />
          </b-form-group>
          <b-form-group :label="$t('unit')" label-for="birimadi">
            <v-select
              v-model="blankNutrientData.birimadi"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="birimadi"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>

          <b-form-group v-if="!oran" label="BMI1" label-for="bmid1">
            <v-select
              v-model="blankNutrientData.bmid1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="products"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
          <b-form-group v-if="!oran" label="BMI2" label-for="bmid2">
            <v-select
              v-model="blankNutrientData.bmid2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="products"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </div>
        <!-- Values -->
        <b-card-actions
          v-if="oran"
          class="w-50 overflow-auto border rounded ml-1"
          style="height: 350px"
          :title="$t('values')"
          action-collapse
          :collapsed="false"
        >
          <!-- Values -->
          <div
            v-for="value in blankNutrientData.values"
            :key="value.id"
            rules="required"
            name="fiyat"
          >
            <b-form-group :label="value.ingredientname" label-for="fiyat">
              <BInputGroup>
                <b-form-input
                  :id="value.ingredientname"
                  v-model="value.deger"
                  step="any"
                  type="number"
                  trim
                  placeholder=""
                />
                <b-input-group-append is-text>
                  <span>{{ value.kod }}</span>
                </b-input-group-append>
              </BInputGroup>
            </b-form-group>
          </div>
        </b-card-actions>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import parse from "uuid/dist/parse";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditNutrientSidebarActive",
    event: "update:is-edit-nutrient-sidebar-active",
  },
  props: {
    isEditNutrientSidebarActive: {
      type: Boolean,
      required: true,
    },
    nutrient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankNutrientData: {},
      oran: false,
      tipid: [
        {
          label: this.$t("real"),
          value: 1,
        },
        {
          label: this.$t("rate"),
          value: 2,
        },
      ],
      birimadi: [
        { label: "Mcal/kg DM", value: "Mcal/kg DM" },
        { label: "%", value: "%" },
        { label: "% DM", value: "% DM" },
        { label: "mg/kg DM", value: "mg/kg DM" },
      ],
      formValidation: formValidation,
      products: [],
    };
  },
  async mounted() {
    await this.fetchProducts();
    this.changed(true);
  },
  methods: {
    changeTip(value) {
      this.oran = value == 1;
    },
    async fetchNewNutrient() {
      this.blankNutrientData = { ...this.nutrient.item };

      var { data } = await this.$http.get(
        `/Nutrients/GetNutrientValuesById/${this.blankNutrientData.id}`
      );

      if (this.nutrient.item.tipid == 2)
        this.tipid = [{ label: this.$t("rate"), value: 2 }];
      else
        this.tipid = [
          {
            label: this.$t("real"),
            value: 1,
          },
          {
            label: this.$t("rate"),
            value: 2,
          },
        ];
      this.oran = this.nutrient.item.tipid == 1;
      this.blankNutrientData.values = data.resultdata;
    },
    async fetchProducts() {
      var products = await store.dispatch(
        "companyProductsModule/fetchCompanyProducts"
      );
      this.products = products.map((x) => {
        return {
          label: x.ingredientname,
          value: x.id,
        };
      });
    },
    async changed(val) {
      if (val == true) await this.fetchNewNutrient();

      this.$emit("update:is-edit-nutrient-sidebar-active", val);
    },
    onSubmit(blankNutrientData) {
      var nutrient = {};
      var values = [];
      nutrient.id = blankNutrientData.id;
      nutrient.birimadi = blankNutrientData.birimadi;
      nutrient.nutrientname = blankNutrientData.nutrientname;
      nutrient.kod = blankNutrientData.kod;
      nutrient.tipid = blankNutrientData.tipid;
      if (!this.oran) {
        nutrient.bmid1 = blankNutrientData.bmid1;
        nutrient.bmid2 = blankNutrientData.bmid2;
      }

      blankNutrientData.values.map((x) => {
        if (x.deger != 0) {
          values.push({
            id: x.id,
            deger: parseFloat(x.deger),
          });
        }
      });
      store
        .dispatch("plantsModule/updateNutrient", {
          nutrient,
          values,
          plantid: store.state.app.selectedPlantId,
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-nutrient-sidebar-active", false);
        });
    },
    async resetblanknutrient() {
      blankNutrientData = await this.fetchNewNutrient;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-nutrient-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
