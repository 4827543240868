import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import router from "@/router";
export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refNutrientListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "kod", label: VueI18n.t("code"), sortable: true },
    { key: "nutrientname", label: VueI18n.t("nutrientName"), sortable: true },
    { key: "tipname", label: VueI18n.t("type"), sortable: false },
    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5, 10, 25, 50];
  const searchQuery = ref("");
  const sortBy = ref(null);
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allNutrients = ref([]);
  const filteredNutrients = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredNutrients.value.length;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          perPage.value * currentPage.value <
        localItemsCount
          ? perPage.value
          : localItemsCount,
      of: allNutrients.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value)
      filteredNutrients.value = allNutrients.value.filter((val) =>
        val.nutrientname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    else filteredNutrients.value = allNutrients.value;
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchNutrients = async (ctx, callback) => {
    var nutrient = await store
      .dispatch("plantsModule/fetchNutrients", router.currentRoute.params.id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("nutrients") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allNutrients.value = nutrient;
    filteredNutrients.value = [...nutrient];
    return nutrient;
  };

  const deleteNutrient = (ctx) => {
    store
      .dispatch("plantsModule/deleteNutrient", ctx)
      .then((response) => {
        fetchNutrients();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("nutrient") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "success",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("nutrient") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchNutrients,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refNutrientListTable,
    deleteNutrient,
    allNutrients,
    filteredNutrients,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
